@font-face {
  font-family: "NeueMachina";
  font-style: light;
  font-weight: light;
  src: local('Neue Machina Light'), url("./fonts/NeueMachina-Light.otf") format('opentype');
}
@font-face {
  font-family: "NeueMachina";
  font-style: normal;
  font-weight: normal;
  src: local('Neue Machina Regular'), url("./fonts/NeueMachina-Regular.otf") format('opentype');
}
@font-face {
  font-family: "NeueMachina";
  font-style: bold;
  font-weight: bold;
  src: local('Neue Machina Ultra Bold'), url("./fonts/NeueMachina-Ultrabold.otf") format('opentype');
}
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: normal;
  src: local("Quicksand Regular"), url("./fonts/Quicksand-Regular.woff") format('woff');
}
@font-face {
  font-family: "Quicksand";
  font-style: medium;
  font-weight: medium;
  src: local("Quicksand Medium"), url("./fonts/Quicksand-Medium.woff") format('woff');
}
@font-face {
  font-family: "Quicksand";
  font-style: light;
  font-weight: light;
  src: local("Quicksand Light"), url("./fonts/Quicksand-Light.woff") format('woff');
}
@font-face {
  font-family: "Quicksand";
  font-style: bold;
  font-weight: bold;
  src: local("Quicksand Bold"), url("./fonts/Quicksand-Bold.woff") format('woff');
}
@font-face {
  font-family: "Questrial";
  font-style: normal;
  font-weight: normal;
  src: local("Questrial Regular"), url("./fonts/Questrial-Regular.ttf") format('truetype');
}
@font-face {
  font-family: "Ubuntu Mono";
  src: local("Ubuntu Mono"), url("./fonts/ubuntu-mono-v15-latin-ext_latin-700.woff") format('woff');
}

:root {
  /* --black:#000; */
  --black:#1a1a1a;
  --mid-gray: #e3e3e3;
  /* --light-gray: #f8f9fa; */
  --light-gray: #f6f7f8;
  --white: #fff;
}

* {
  color: var(--black);
  font-family: 'Quicksand', 'Segoe UI', 'Roboto', 'Ubuntu', sans-serif;
  font-size: large;
  /* font-weight: 500; */
  /* font-weight: medium; */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.name {
  /* font-family: 'NeueMachina'; */
}

h1 {
  /* font-family: "NeueMachina"; */
  font-weight: normal;
}

h1, h2 {
  font-family: 'Questrial';
  /* font-weight: bolder; */
}

p {
  text-align: justify;
  text-align-last: left;
}

.bold {
  font-weight: bold;
}

.subtitle {
  font-size: x-large;
  text-align: justify;
  text-align-last: left;
}
.project-subtitle {
  text-align: center;
  font-size: x-large;
}
@media screen and (max-width: 768px) {
  .project-subtitle {
    text-align: justify;
    text-align-last: left;
  }
}

.quote::before { content: '"' }
.quote {
  font-style: italic;
  font-size: large;
  border-left: 5px solid var(--mid-gray);
  padding: 10px 20px 10px 15px;
  background-color: var(--light-gray);
}
.quote::after { content: '"' }


.center-vertically {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.caps {
  text-transform: uppercase;
  text-decoration: none;
}
.gray-bg {
  background-color: var(--light-gray);
}

.work-card {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: var(--black);
}
.work-card:hover .overlay {
  opacity: 1;
}
.mobile {
  opacity: 0.8;
  /* mix-blend-mode: overlay; */
}
.text {
  color: var(--black);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "Questrial";
  line-height: 2.5ch;
}
.dark {
  color: var(--white);
}

.project-nav {
  padding: 0;
  width: fit-content;
  margin: 0;
  position: sticky;
  top: 20%;
  left: 9%;
  transform: translate(-50%, -50%);

  transform-origin: 0 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.project-nav a {
  font-family: 'Questrial';
  text-decoration: none;
  color: var(--black);
}
@media screen and (max-width: 1676px) {
  .project-nav {
    left: 6%;
  }
}
@media screen and (max-width: 1486px) { /*1700*/
  .project-nav {
    left: 4%;
  }
}
@media screen and (max-width: 1199px) { /*1500*/
  .project-nav {
    left: 4%;
  }
}
@media screen and (max-width: 991px) { /*1500*/
  .project-nav {
    left: 4%;
  }
}

.img-shadow {
  -webkit-filter: drop-shadow(10px 10px 10px #9995);
  filter: drop-shadow(10px 10px 10px #9995);
}
.img-shadow-sm {
  -webkit-filter: drop-shadow(10px 10px 10px #9995);
  filter: drop-shadow(5px 5px 5px #9992);
}


.proto-button {
  border-radius: 0;
  text-transform: uppercase;
}
pre {
  display: block;
  font-family: "Ubuntu Mono", monospace;
  line-height: 2.1ch;
  margin: 0;
  padding: 0;
  border: 0;
}
@media screen and (max-width: 768px) {
  pre {
    font-size: x-small;
    font-weight: bold;
  }
}


.pointer {
  cursor: pointer;
}

.modal {
  cursor: pointer;
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding: 5vw 0;
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: #1a1a1ad1; /* Black w/ opacity */
}

.modal-content {
  border-width: 0;
  border-radius: 0;
  cursor: default;
  margin: auto;
  display: block;
  width: calc(100% - 10vw);
}

.modal-content, .modal-caption, .modal-button {
  animation-name: zoomIn;
  animation-duration: 0.3s;
}
@keyframes zoomIn {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

.modal-caption {
  margin: auto;
  display: block;
  width: calc(100% - 10vw);
  text-align: center;
  color: var(--white);
  padding: 10px 0;
  height: 150px;
  font-size: larger;
  font-family: "Questrial";
}

.modal-button {
  position: absolute;
  z-index: 2;
  right: 5vw;
  font-weight: bold;
  font-size: large;
}

/* 100% Image Width on Smaller Screens */
@media screen and (max-width: 1200px){
  .modal-content {
    width: 100%;
  }
  .modal-button {
    right: 0;
  }
}